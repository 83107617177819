module.exports = {
    REWARD_TYPES: {
        GEM: 1,
        RANK_TICKET: 2,
        GGT: 3,
    },

    ORDINAL_DISPLAY_REWARD: {
        GGT: 0,
        GEM: 1,
        RANK_TICKET: 2,
    },

    GOGA_RANK_REWARD_CONFIG: {
        key: 'GOGA_RANK_REWARD',
        default_data: [
            {
                start: 1,
                end: 1,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 2,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 500,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 1,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 2,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 750,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 2,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1000,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 2,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1250,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 4,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 2,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1875,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 4,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 22,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2500,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 5,
                            is_active: true
                        }
                    ]
                }
            },
            {
                start: 2,
                end: 2,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 480,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 720,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 960,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1200,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1800,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2400,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 4,
                            is_active: true
                        }
                    ]
                }
            },
            {
                start: 3,
                end: 3,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 460,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 690,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 920,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1150,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1725,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2300,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 4,
                            is_active: true
                        }
                    ]
                }
            },
            {
                start: 4,
                end: 15,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 440,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 660,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 880,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 1,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1100,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1650,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2200,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ]
                }
            },
            {
                start: 16,
                end: 35,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 15,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 420,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 10,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 3,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 630,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 6,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 840,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 1,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 9,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1050,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1575,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 14,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2100,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ]
                }
            },
            {
                start: 36,
                end: 50,
                reward: {
                    1: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 400,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    2: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 600,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 11,
                            is_active: false
                        }
                    ],
                    3: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 800,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 1,
                            is_active: true
                        }
                    ],
                    4: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1000,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    5: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 1500,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 2,
                            is_active: true
                        }
                    ],
                    6: [
                        {
                            type: 3,
                            amount: 12,
                            is_active: false
                        },
                        {
                            type: 1,
                            amount: 2000,
                            is_active: true
                        },
                        {
                            type: 2,
                            amount: 3,
                            is_active: true
                        }
                    ]
                }
            }
        ]
    },

    MODULE_CATEGORY: 'goga_rank',
}
