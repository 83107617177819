import api from '@/libs/axios'

export default {
  async get(params) {
    let response = await api.get('/configs/detail', { params })
    return response.data.data.config
  },
  async update(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
}
