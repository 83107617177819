<template>
  <div class="config-table">
    <b-table :items="configs" :fields="fields" responsive>
      <template #cell(position)="data">
        <div v-if="configId == data.index">
            <b-form-input v-model="data.item.start" placeholder="start" />
            <b-form-input v-model="data.item.end" placeholder="end" />
        </div>
        <p v-else>{{ getPosition(data.item) }}</p>
      </template>

      <template #cell(iron)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.iron][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.iron], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.iron], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.iron], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(bronze)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.bronze][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.bronze], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.bronze], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.bronze], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(silver)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.silver][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.silver], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.silver], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.silver], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(gold)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.gold][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.gold], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.gold], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.gold], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(platinum)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.platinum][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.platinum], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.platinum], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.platinum], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(diamond)="data">
        <div v-if="configId == data.index" class="column-level">
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GEM].is_active">Gem</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GEM].is_active"
                v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GEM].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active">Rank ticket</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.RANK_TICKET].is_active"
                v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.RANK_TICKET].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8" class="check-box">
              <b-form-checkbox v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GGT].is_active">GGT</b-form-checkbox>
            </b-col>
            <b-col sm="4">
              <b-form-input
                v-if="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GGT].is_active"
                v-model="data.item.reward[levels.diamond][ORDINAL_DISPLAY_REWARD.GGT].amount" placeholder="0"
              />
              <span v-else>---</span>
            </b-col>
          </b-row>
        </div>
        <div v-else class="column-level">
          <b-row>
            <b-col sm="8">Gem</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.diamond], REWARD_TYPES.GEM) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">Rank ticket</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.diamond], REWARD_TYPES.RANK_TICKET) }}</b-col>
          </b-row>
          <b-row>
            <b-col sm="8">GGT</b-col>
            <b-col sm="4">{{ getRewardAmount(data.item.reward[levels.diamond], REWARD_TYPES.GGT) }}</b-col>
          </b-row>
        </div>
      </template>

      <template #cell(action)="data">
        <div class="d-flex flex-end">
          <b-button
            @click="editOrUpdateConfig(data.index)"
            size="sm"
            style="margin-right: 5px"
            variant="primary"
          >{{ configId == data.index ? "Save" : "Edit" }}</b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="confirmDelete(data.index)"
          >{{ configId == data.index ? "Cancel" : "Delete" }}</b-button>
        </div>
      </template>
    </b-table>
    <div class="add-config">
      <b-button class="text-center" variant="primary" @click="addConfig">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span>Add config</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import service from "../../service";
import defaultObject from './defaultObject'
import { REWARD_TYPES, ORDINAL_DISPLAY_REWARD } from './../../constants'

export default {
  props: {
    config: { type: Object, default: () => { } },
  },

  data() {
    return {
      fields: [
        { key: 'position', stickyColumn: true, isRowHeader: true, variant: 'primary' },
        "iron",
        "bronze",
        "silver",
        "gold",
        "platinum",
        "diamond",
        { key: "action", label: "", stickyColumn: true,  isRowHeader: true },
      ],
      levels: {
        "iron": 1,
        "bronze": 2,
        "silver": 3,
        "gold": 4,
        "platinum": 5,
        "diamond": 6,
      },
      REWARD_TYPES,
      ORDINAL_DISPLAY_REWARD,
      configId: null,
      configs: []
    };
  },

  watch: {
    config(value) {
      this.configs = JSON.parse(JSON.stringify(value.value))
    }
  },

  methods: {
    getPosition(config) {
      if (!config.end || !config.start) {
        return config.start ? '>=' + config.start : config.end + '<='
      }

      if (config.end === config.start) {
        return config.end
      }

      return config.start + '-' + config.end
    },

    getRewardAmount(reward, type) {
      const rw = reward.find(rw => rw.type === type)
      return rw.is_active ? rw.amount : '--'
    },

    async editOrUpdateConfig(index) {
      if (!(this.configId + '') || this.configId != index) {
        this.configId = index;
      } else {
        const response = await this.updateConfigs();
        if (response.data.type == "SUCCESS") {
          this.resetData();
          this.$emit("getConfig");
        }
      }
    },

    async updateConfigs() {
      const dataUpdate = {
        ...this.config,
        value: this.configs,
      }

      return await service.update(dataUpdate);
    },

    async cancelOrDelete(id) {
      if (this.configId == id) {
        this.resetData();
      } else {
        this.configs = [...this.configs.splice(0, id), ...this.configs.slice(1)]
        await this.updateConfigs()
        this.$emit("getConfig");
      }
    },

    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.cancelOrDelete(id);
          }
        });
    },

    resetData() {
      this.configId = null;
      this.configs = JSON.parse(JSON.stringify(this.config.value))
    },

    addConfig() {
      this.configs.push(JSON.parse(JSON.stringify(defaultObject)))
      this.configId = this.configs.length - 1
    },
  },
};
</script>
<style lang="scss">
.config-table {
  table {
    td {
      padding-top: 0.72rem !important;
      padding-bottom: 0.72rem !important;
      p {
        margin: unset;
      }
    }
    td[aria-colindex="8"] {
      width: 120px;
    }
    .flex-end {
      justify-content: flex-end
    }
  }
  .add-config {
    width: 100%;
    button {
      margin: auto;
      display: block;
    }
  }
  .column-level {
    min-width: 180px !important;
    .check-box {
      display: flex;
      align-items: center;
    }
    input {
      padding: 0 30% !important;
      height: 30px;
      margin: 4px 0;
    }
  }
}
</style>
