export default {
  start: null,
  end: null,
  reward: {
    1: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
    2: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
    3: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
    4: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
    5: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
    6: [
      {
        type: 1,
        amount: null,
        is_active: false
      },
      {
        type: 2,
        amount: null,
        is_active: false
      },
      {
        type: 3,
        amount: null,
        is_active: false
      },
    ],
  }
}
